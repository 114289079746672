<template>
	<section v-editable="blok" v-if="breakdowns.length" class="blok-breakdown">
		<v-layout wrap>
			<v-flex v-for="breakdown in breakdowns" :key="breakdown._uid" xs12 md6>
				<article class="item">

					<div class="content">
						<p v-if="breakdown.date"><strong>Bekend sinds:</strong><br/> {{ breakdown.date|formatDate('D MMM YYYY') }}</p>

						<s-markdown
							:tag="'div'"
							:value="breakdown.text"
							class="text"
						/>
					</div>

				</article>
			</v-flex>
		</v-layout>
	</section>
</template>

<script>
	import SMarkdown from '@/components/util/SMarkdown';

	export default {
		name: 'Breakdown',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SMarkdown: SMarkdown
		},
		data() {
			return {
				currentStory: null
			}
		},
		async mounted() {
			await this.loadStory();

			this.$storybridge.on(['input', 'published', 'change'], (event) => {
				if (event.action === 'input') {
					if (event.story.full_slug === 'beheer/storingen') {
						this.currentStory = event.story;
					}
				}
			});
		},
		computed: {
			breakdowns()
			{
				if (!this.currentStory) {
					return [];
				}

				const website = this.$store.state.config.site;

				const items = [];
				this.currentStory.content.blocks.forEach((item) =>
				{
					if (item.showOn.indexOf(website) === -1) {
						return;
					}

					items.push(item);
				});

				return items;
			}
		},
		methods: {
			async loadStory()
			{
        try {
				  const res = await this.$storyapi.get('cdn/stories/beheer/storingen');

          this.currentStory = res.data.story;
        } catch (e) {
					console.error(e.toString());
        }
			}
		}
	}
</script>

<style lang="scss" scoped>
	.blok-breakdown
	{
		/*margin: 12px 0;*/
		/*padding: 0;*/

		margin: 0;
		padding: 12px 0;

		text-align: left;

		.item
		{
			height: 100%;
			width: 100%;

			background-color: #ffffff;

			border-radius: 5px;

			overflow: hidden;

			.content
			{
				padding: 20px;
			}
		}
	}
</style>
