<template>
	<v-dialog v-if="breakdowns.length" v-model="dialog" max-width="900" content-class="breakdown-dialog">
		<v-card>
			<v-card-title class="pt-4 pb-2 pl-4 pr-4">
				<h1><span class="material-icons">warning</span>Mededeling</h1>
			</v-card-title>
			<v-card-text class="pt-2 pb-2 pl-4 pr-4">
				<div v-for="breakdown in breakdowns" :key="breakdown._uid" class="breakdown">
					<s-markdown
						:tag="'div'"
						:value="breakdown.text"
						class="text"
					/>
				</div>
			</v-card-text>
			<v-card-actions class="pt-2 pb-4 pl-4 pr-4">
				<v-spacer></v-spacer>
				<s-button
					:buttonType="'button'"
					@click="closeDialog()"
				>
					Verberg
				</s-button>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import SButton from '@/components/util/SButton'
	import SMarkdown from '@/components/util/SMarkdown';

	export default {
		name: 'BreakdownDialog',
		data() {
			return {
				dialog: false,
				breakdowns : []
			}
		},
		components: {
			SButton: SButton,
			SMarkdown: SMarkdown
		},
		watch: {
			dialog (value) {
				!value && this.closeDialog();
			}
		},
		async mounted() {
			let data = sessionStorage.getItem('breakdown');
			if (data === 'dialogClose')
			{
				return;
			}

			await this.loadBreakdowns();
		},
		methods: {
			async loadStory()
			{
        try {
          const res = await this.$storyapi.get('cdn/stories/beheer/storingen');

          return res.data.story;
        } catch (e) {
          console.error(e.toString());
        }
			},
			async loadBreakdowns()
			{
				const story = await this.loadStory();
        if (!story) {
          return;
        }

				const website = this.$store.state.config.site;

				const breakdowns = [];
				story.content.blocks.forEach((breakdown) =>
				{
					if (breakdown.showOn.indexOf(website) === -1 || !breakdown.nationwide) {
						return;
					}

					breakdowns.push(breakdown);
				});

				this.breakdowns = breakdowns;
				this.dialog = true;
			},
			closeDialog()
			{
				this.dialog = false;

				sessionStorage.setItem('breakdown', 'dialogClose');
			}
		}
	}
</script>

<style lang="scss">
	.breakdown-dialog
	{
		h1
		{
			padding-left: 40px;
			padding-bottom: 0;

			position: relative;

			color: #333333;
			font-size: 1.4rem;
			font-weight: 700;
			line-height: 1.25;

			span.material-icons
			{
				position: absolute;
				top: 50%;
				left: 0;

				font-size: 1.6rem;

				transform: translateY(-50%);
			}
		}

		.breakdown
		{
			h1,
			h2,
			h3,
			h4,
			h5,
			h6
			{
				color: red;
			}
		}
	}
</style>
